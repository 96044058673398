import React from "react"
import ContentLoader from 'react-content-loader'

const loaderCircles = () => (
    <ContentLoader 
        speed={1}
        width={400}
        height={20}
        viewBox="0 0 400 20"
        backgroundColor="#f3f3f3"
        foregroundColor="#DDDDDD"
      >
        <rect x="140" y="0" rx="0" ry="0" width="52" height="24" /> 
        <rect x="280" y="0" rx="0" ry="0" width="52" height="24" /> 
        <rect x="210" y="0" rx="0" ry="0" width="52" height="24" /> 
        <rect x="350" y="0" rx="0" ry="0" width="52" height="24" /> 
        <rect x="70" y="0" rx="0" ry="0" width="52" height="24" /> 
        <rect x="0" y="0" rx="0" ry="0" width="52" height="24" />
      </ContentLoader>
)

export default loaderCircles