import React, { useState, useEffect } from "react"
import Marker from '../svg/marker.svg'
import Cta from './cta'
import LoaderRectangles from './loaderRectangles'
import LoaderLists from './loaderLists'

const VolunteerMap = () => {
    const [placeholderState, setPlaceholderState] = useState(true);
    const [volunteerLocation, setVolunteerLocation] = useState('');
    const [volunteerLocations, setVolunteerLocations] = useState('');
    const [volunteerLocationState, setVolunteerLocationState] = useState('WA');
    
    const setVolunteerState = (key) => {
        setVolunteerLocationState(value => key)

        document.getElementById('location-listings').scrollTop = 0
    }

    const ctaConfig = [
        { title: 'Read About Us', to: '/' },
        { title: 'How to Volunteer', to: '/volunteer' }
    ]

    useEffect(() => {
        let volunteerLocationsRestURL = `${process.env.GATSBY_API_URL}/wp-json/wp/v2/volunteer-locations?_fields=id,title,acf&per_page=100`
        fetch(volunteerLocationsRestURL)
            .then(res => res.json())
            .then((res) => {
                setVolunteerLocations(res)
                setPlaceholderState(false)
            })
            .catch(err => { throw err })
    }, [])

    // Group Object by States
    let volunteerLocationsByState = Object.values(volunteerLocations).reduce((r, a) => {
        r[a.acf.state.label] = [...r[a.acf.state.label] || [], a];

        return r
    }, {})

    return (
        <>
            <div id="locations" className="mt-20 relative z-10">
                <div className="m-auto max-w-2xl text-center mb-10">
                    <h2 className="pb-5 md:pb-0">Find a location to volunteer</h2>
                    <p>Hair Aid sends teams of volunteer hairdressers to work with Australian organisations that support those in our own communities that need a hand.</p>
                </div>
                <div className="grid grid-cols-2">
                    <div className="col-span-2 md:col-span-1 h-64 md:h-full">
                        <iframe 
                            title="Community Cuts Locations" 
                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyALDnpLhIAJ_uqpuHCwowod9QRUWZ7rdYQ&q=${volunteerLocation ? volunteerLocation : 'Australia'}`} 
                            width="100%" 
                            height="100%" 
                            frameBorder="0" 
                            allowFullScreen
                        ></iframe>
                    </div>
                    <div className="col-span-2 md:col-span-1">
                        <div className="grid grid-cols-1">
                            <div className="states py-4 px-4 md:px-8 overflow-x-auto" style={{background:`#168DC7`}}>
                                { placeholderState ? <LoaderRectangles /> : 
                                    Object.entries(volunteerLocationsByState)
                                        .sort((a,b) => b[0].localeCompare(a[0]))
                                        .map(([state, value]) => {
                                        return (
                                            <>
                                                <span 
                                                    key={state} 
                                                    role="button" 
                                                    tabIndex={0} 
                                                    className={`rounded py-2 px-4 cursor-pointer ${volunteerLocationState === state ? 'bg-black' : ''}`} 
                                                    onClick={event => setVolunteerState(state)} 
                                                    onKeyDown={event => setVolunteerState(state)}
                                                >{state}</span>
                                            </>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div id="location-listings" className="overflow-scroll h-48 md:h-96 relative" style={{ background: `#1795d3` }}>
                            <div className="grid grid-cols-5">
                                { placeholderState ? <LoaderLists /> : 
                                    Object.keys(volunteerLocations)
                                        .filter((keyName, keyIndex) => volunteerLocationState === volunteerLocations[keyName].acf.state.label)
                                        .sort((a, b) => {
                                            return volunteerLocations[a].acf.city.localeCompare(volunteerLocations[b].acf.city) || volunteerLocations[a].title.rendered.localeCompare(volunteerLocations[b].title.rendered)
                                        })
                                        .map(function(keyName, keyIndex) {
                                        return (
                                            <>
                                                <button onClick={event => setVolunteerLocation(volunteerLocations[keyName].acf.address)} 
                                                className="relative active:bg-blue-900 text-left col-span-4 py-4 px-4 md:px-8 border-b-2 focus:outline-none cursor-pointer">
                                                    <span className="text-1xl md:text-2xl block" dangerouslySetInnerHTML={{ __html: volunteerLocations[keyName].title.rendered }}></span>
                                                    <span className="text-sm">{volunteerLocations[keyName].acf.town}, {volunteerLocations[keyName].acf.city}</span>
                                                    <span className="text-sm block mt-5"><span className="font-bold text-blue-200">Time:</span> {volunteerLocations[keyName].acf.volunteer_time ? volunteerLocations[keyName].acf.volunteer_time : '--:--'}</span>
                                                </button>
                                                <div className="col-span-1 py-4 md:px-8 border-b-2 border-blue-100 flex justify-center items-center">
                                                    <Marker className="w-10" />
                                                </div>
                                            </>
                                        )
                                    })
                                 }
                            </div>
                        </div>
                    </div>
                </div>
                <Cta config={ctaConfig} />
            </div>
        </>
    )
}

export default VolunteerMap