import React from 'react'
import Seo from "../components/seo"
import Layout from "../components/layout"
import VolunteerMap from "../components/volunteerMap"

const locationsPage = () => {
    return(
        <Layout>
        <Seo title="Locations" />
        <div className="container">
            <h1 className="text-center">Locations</h1>
            <VolunteerMap />
        </div>
        </Layout>
    )
}

export default locationsPage